.body-preview {
  width: 96.5%;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-top: 5px solid #d8d8d8;
  position: absolute;
  z-index: -2;
  margin-left: -10px;
}

.title-publication-preview {
  opacity: 0.7;
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
  margin: 20px 0px 20px;
}

.title-publication {
  padding: 0 16px;
  font-family: Rubik;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  word-break: break-word;
  color: #212121;
}

.body-question-preview {
  width: 100%;
  display: flex;
  padding: 0px 15px 0px;
  flex-direction: column;
}

.body-question-preview p {
  text-align: left;
}

.text-preview-element {
  padding: 1rem 0;
  word-break: break-word;
}

.text-preview-element p + p {
  margin-top: 1rem;
}

.text-preview-element ul {
  list-style-type: disc;
}

.container-fluid-answer {
  width: 100%;
  padding: 16px;
  border-radius: 20px;
  margin-bottom: 10px;
  background: #f5f7fa;
  text-align: start;
}

.container-fluid-text {
  width: 100%;
  padding: 16px;
  border-radius: 20px;
  margin-bottom: 10px;
  text-align: start;
}

.body-container-form {
  display: flex;
  border-radius: 2px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  border-top: 5px solid #d8d8d8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.img-form-result {
  width: 100%;
  height: 100%;
}

.title-preview-form {
  font-size: 18px;
  font-weight: 600;
  color: #333947;
  line-height: 28px;
  text-align: start;
  font-style: normal;
  font-family: Rubik;
}

.preview-form {
  height: 70%;
  margin-top: 16px;
}

.img-alteration {
  margin-right: 16px;
  height: 18px;
}

.preview-title-container-fluid {
  width: 100%;
  display: flex;
  font-size: 18px;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 16px;
  padding: 20px 0px 16px;
  justify-content: center;
  background-color: #f4f6fb;
}

.input-preview-question {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 8px;
  background: #ffffff;
}

.input-preview-question::placeholder {
  padding: 16px;
  font-size: 18px;
  font-weight: 300;
  color: #ccd2e0;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
}

.preview-container-form {
  width: 33%;
}

.text-details-form {
  font-size: 18px;
  color: #7d86a1;
  font-weight: 300;
  line-height: 24px;
  font-style: normal;
  font-family: Rubik;
}

.header-preview-post {
  width: 105%;
  margin-left: -10px;
  padding-top: 20px;
  padding-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.header-preview-post-content {
  font-family: Rubik;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 75%;
  padding-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
}

.header-preview-post-content h2 {
  font-size: 18px;
}

.header-preview-post-content span {
  font-size: 12px;
  color: rgba(80, 80, 80, 0.5);
}
